<template>
	<div class="wrapper fall-workshop">
		<div class="page-header page-header-small">
			<parallax
				class="page-header-image"
				style="background-image: url('img/workshop/umcbg.jpg')"
			></parallax>
			<div class="content-center">
				<div class="row">
					<div class="col-md-8 ml-auto mr-auto text-center">
						<h2 class="title">22nd Annual UMC Conference</h2>
						<h4>
							<strong>
								January 24 & 25, 2025<br />The Marriott University Park<br />Salt
								Lake City</strong
							>
						</h4>
					</div>
				</div>
			</div>
		</div>
		<div class="section">
			<div class="container">
				<div class="row">
					<div class="col-md-12 ml-auto mr-auto">
						<collapse>
							<h3 class="title">
								Workshop Presenters
								<small>(click boxes/arrows below to expand)</small>
							</h3>

							<collapse-item title="WORKSHOP A: 10:45 - 11:45 AM" name="1">
								<div class="row">
									<div class="col-md-4 ml-auto mr-auto">
										<h4>Randall Klein</h4>
										<img
											src="img/presenters25/Randall Klein.jpg"
											height="300px"
											width="250px"
										/>
									</div>
									<div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
										<h4>
											Supplemental Games & Activities for Decoding Practice
										</h4>
										<p>
											Early reading specialist and Montessori Master Teacher
											Randall Klein will demonstrate supplemental games and
											activities that give beginning and struggling readers a
											concrete understanding of the alphabetic principle and
											launch them into strong alphabet knowledge, confident
											decoding and proficient word identification.
										</p>
									</div>
								</div>
								<hr />
								<div class="row">
									<div class="col-md-4 ml-auto mr-auto">
										<h4>Liz Goodman</h4>
										<img
											src="img/presenters25/Liz Goodman.jpg"
											height="300px"
											width="200px"
										/>
									</div>
									<div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
										<h4>
											Cultivating a Connected Partnership with Parents and
											Caregivers
										</h4>
										<p>
											We will explore using servant leadership concepts to
											cultivate a connected partnership with parents and
											caregivers; including navigating conferences, parent
											education and communication to best serve families in our
											care.
										</p>
									</div>
								</div>
								<hr />
								<div class="row">
									<div class="col-md-4 ml-auto mr-auto">
										<h4>Elise Angeline</h4>
										<img
											src="img/presenters25/Elise Angeline.jpg"
											height="300px"
											width="250px"
										/>
									</div>
									<div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
										<h4>
											The Art/Science of Movement: Understanding Individual Body
											Language through Movement
										</h4>
										<p>
											Join us as we explore strategies to help students
											recognize and interpret the language of their bodies from
											an early age. We will also reflect on our own body care as
											educators, focusing on how to respond effectively to our
											physical and mental needs amidst the demands of the
											academic year.
										</p>
									</div>
								</div>
								<hr />
								<div class="row">
									<div class="col-md-4 ml-auto mr-auto">
										<h4>Tamela Mills</h4>
										<img
											src="img/presenters25/Tamela Mills.jpg"
											height="300px"
											width="250px"
										/>
									</div>
									<div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
										<h4>
											Designing an Adolescent Program for & with Your Teens
										</h4>
										<p>
											Why choose a Montessori adolescent program? Promoting your
											unique adolescent program to families is an opportunity to
											redesign & reinvent alongside your teens. We will workshop
											how Montessori supports this process.
										</p>
									</div>
								</div>
								<hr />
								<div class="row">
									<div class="col-md-4 ml-auto mr-auto">
										<h4>Chip Reichanadter</h4>
										<img
											src="img/presenters25/Chip Reichanadter.png"
											height="300px"
											width="200px"
										/>
									</div>
									<div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
										<h4>
											Mindful Moments: Practices for Centering, Connection, and
											Calm
										</h4>
										<p>
											An interactive workshop exploring gentle mindfulness
											techniques and meditations for both children and teachers,
											fostering self-regulation, presence, and meaningful
											connection to self, others, and the world.
										</p>
									</div>
								</div>
								<hr />
								<div class="row">
									<div class="col-md-4 ml-auto mr-auto">
										<h4>Hillary Burrows and Piper Nuetzel</h4>
										<!-- <img
											src="img/presenters24/Julie Wand.png"
											height="300px"
											width="200px"
										/> -->
									</div>
									<div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
										<h4>Unlocking Literacy Through Creative Expression</h4>
										<p>
											Join us for an immersive workshop experience that delves
											into the rich tapestry of language and literacy
											development through the lens of creativity. In this
											session, we'll explore the interconnected worlds of
											poetry, story writing, and the arts, discovering how each
											medium serves as a powerful tool for expression and
											language development.
										</p>
									</div>
								</div>
								<hr />
								<div class="row">
									<div class="col-md-4 ml-auto mr-auto">
										<h4>Leticia Bollinger</h4>
										<img
											src="img/presenters25/Leticia Bollinger.jpg"
											height="300px"
											width="200px"
										/>
									</div>
									<div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
										<h4>Science of Reading and Montessori Literacy</h4>
										<p>
											Understanding what the Science of Reading is and how it
											incorporates to the Montessori Language curriculum.
										</p>
									</div>
								</div>
								<hr />
								<div class="row">
									<div class="col-md-4 ml-auto mr-auto">
										<h4>Nathy Canales</h4>
										<img
											src="img/presenters25/Nathy Canales.jpeg"
											height="300px"
											width="200px"
										/>
									</div>
									<div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
										<h4>Español en el Aula</h4>
										<p>
											Importancia del aprendizaje de español en clase en niños
											de 0-6 años. Este taller es dictado en Español <br />*
											This workshop is in Spanish
										</p>
									</div>
								</div>
							</collapse-item>
							<collapse-item title="WORKSHOP B: 1:45 - 2:45 PM" name="2">
								<div class="row">
									<div class="col-md-4 ml-auto mr-auto">
										<h4>Majo Moss</h4>
										<img
											src="img/presenters25/Majo Moss.jpg"
											height="300px"
											width="200px"
										/>
									</div>
									<div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
										<h4>The Art and Science of Montessori Therapy</h4>
										<p>
											The Montessori Therapy teacher adapts the Montessori
											philosophy in a therapy setting. You can learn more about
											how a Montessori therapy teacher works with a person that
											has a diagnosis with limited time, specific goals, works
											with parents, gives individual support, and creates
											adapted material to work on those specific goals.
										</p>
									</div>
								</div>
								<hr />
								<div class="row">
									<div class="col-md-4 ml-auto mr-auto">
										<h4>Jen Hall & Cristy Taylor</h4>
										<img
											src="img/presenters25/JH.jpg"
											height="300px"
											width="200px"
										/>
										<br /><br />
										<img
											src="img/presenters25/CT.jpg"
											height="300px"
											width="200px"
										/>
									</div>
									<div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
										<h4>Culinary Arts in the Montessori Environment</h4>
										<p>
											Explore a hands-on presentation of strategies for teaching
											cooking in early childhood through upper elementary.
										</p>
									</div>
								</div>
								<hr />
								<div class="row">
									<div class="col-md-4 ml-auto mr-auto">
										<h4>Stephanie Van Fleet</h4>
										<img
											src="img/presenters25/SVF.jpg"
											height="300px"
											width="200px"
										/>
									</div>
									<div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
										<h4>Fostering Grace and Courtesy Through Observation</h4>
										<p>
											Explore the vital role of observation in cultivating an
											environment where grace and courtesy thrive. Delve into
											the principles of grace and courtesy to understand how
											these values support social development. Leave equipped
											with tools and confidence to foster an atmosphere of
											kindness, respect, and cooperation.
										</p>
									</div>
								</div>
								<hr />
								<div class="row">
									<div class="col-md-4 ml-auto mr-auto">
										<h4>Dana Anderson</h4>
										<img
											src="img/presenters25/Dana Anderson .png"
											height="300px"
											width="200px"
										/>
									</div>
									<div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
										<h4>
											The Power of Storytelling for Montessori Adolescents
										</h4>
										<p>
											Storytelling is a powerful learning tool and an
											increasingly important life skill. Montessori educator
											Dana Anderson will show how stories can energize and bring
											deeper meaning to your academic lessons.
										</p>
									</div>
								</div>
								<hr />
								<div class="row">
									<div class="col-md-4 ml-auto mr-auto">
										<h4>Rachelle Stanger</h4>
										<img
											src="img/presenters25/Rachelle Stanger.jpg"
											height="300px"
											width="200px"
										/>
									</div>
									<div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
										<h4>Peace Education for Infants and Toddlers</h4>
										<p>
											What does peace mean? To you? To your students? In the
											world around you? How can you find peace, while educating
											others about peace? Join me as we discuss and practice
											ideas and activities to promote peace education!
										</p>
									</div>
								</div>
								<hr />
								<div class="row">
									<div class="col-md-4 ml-auto mr-auto">
										<h4>Jennifer Spikner</h4>
										<img
											src="img/presenters25/JenniferSpikner.jpg"
											height="300px"
											width="200px"
										/>
									</div>
									<div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
										<h4>Adapt!</h4>
										<p>
											To survive, life must adapt. Adaptation is among the most
											critical life science concepts for students to grasp.
											Learn fascinating examples of adaptation that your lower
											elementary students will love!
										</p>
									</div>
								</div>
								<hr />
								<div class="row">
									<div class="col-md-4 ml-auto mr-auto">
										<h4>Julie Wand</h4>
										<img
											src="img/presenters25/Julie Wand.png"
											height="300px"
											width="200px"
										/>
									</div>
									<div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
										<h4>Surviving to Thriving</h4>
										<p>
											Balancing the excitement of teaching with the pressure to
											succeed can be overwhelming. But, with a few key
											strategies, you can truly thrive as a first year teacher.
										</p>
									</div>
								</div>
								<hr />
								<div class="row">
									<div class="col-md-4 ml-auto mr-auto">
										<h4>Brandi Allen & Margaret Mc Donald</h4>
										<img
											src="img/presenters25/Brandi Allen.jpeg"
											height="300px"
											width="200px"
										/>
									</div>
									<div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
										<h4>Round Table on Teacher Led Schools</h4>
										<p>
											Pinyon Montessori, the first Wildflower School in Utah,
											has created a space that is accessible and equitable to
											all socioeconomic backgrounds. Join us to discuss teacher
											led, community imbedded and intentional small schools.
										</p>
									</div>
								</div>
							</collapse-item>
							<collapse-item title="WORKSHOP C: 3:15 - 4:15 PM" name="3">
								<div class="row">
									<div class="col-md-4 ml-auto mr-auto">
										<h4>Emily Lee Grella</h4>
										<img
											src="img/presenters25/Emily Lee.jpeg"
											height="300px"
											width="200px"
										/>
									</div>
									<div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
										<h4>
											Deepening Our Personal and Team Communication Skills
										</h4>
										<p>
											Come join us as we share strategies we can add to our
											communication tool belt. We will learn together how we can
											better understand our own communication style, our peers’,
											and how to use and understand both effectively in our
											programs.
										</p>
									</div>
								</div>
								<hr />
								<div class="row">
									<div class="col-md-4 ml-auto mr-auto">
										<h4>Kristina Perry</h4>
										<img
											src="img/presenters25/Kristina Perry.jpg"
											height="300px"
											width="200px"
										/>
									</div>
									<div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
										<h4>Importance of Pencil to Paper and Learning to Read</h4>
										<p>
											How is writing and reading connected. Have you wondered
											why students struggle with reading and the bare the same
											students that struggle with writing? Why is using writing
											instruments in the hand important?
										</p>
									</div>
								</div>
								<hr />
								<div class="row">
									<div class="col-md-4 ml-auto mr-auto">
										<h4>Andrew Faulstich</h4>
										<img
											src="img/presenters25/Andrew Faulstich.jpg"
											height="300px"
											width="200px"
										/>
									</div>
									<div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
										<h4>
											The Enlightened Guide: The Art and Science of Spiritual
											Development and Building Resilient Communities of Practice
										</h4>
										<p>
											This session will explore a cycle for spiritual
											development which transforms individuals and schools.
											Participants are invited to engage in reflection,
											resilience building, and an examination of equity in their
											school contexts.
										</p>
									</div>
								</div>
								<hr />
								<div class="row">
									<div class="col-md-4 ml-auto mr-auto">
										<h4>Shawn Edwards</h4>
										<img
											src="img/presenters25/Shawn Edwards.jpg"
											height="300px"
											width="200px"
										/>
									</div>
									<div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
										<h4>
											Contributing to a Culture of Accountability and
											Authenticity in Your School
										</h4>
										<p>
											Adult relationships influence and inform the tone and
											culture of your school. Learn how to identify drama in
											your schools and how to transform that drama into a
											culture of personal responsibility, empowerment and
											authenticity.
										</p>
									</div>
								</div>
								<hr />
								<div class="row">
									<div class="col-md-4 ml-auto mr-auto">
										<h4>Rachelle Stanger</h4>
										<img
											src="img/presenters25/Rachelle Stanger.jpg"
											height="300px"
											width="200px"
										/>
									</div>
									<div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
										<h4>Peace Education for Early Childhood</h4>
										<p>
											What does peace mean? To you? To your students? In the
											world around you? How can you find peace, while educating
											others about peace? Join me as we discuss and practice
											ideas and activities to promote peace education!
										</p>
									</div>
								</div>
								<hr />
								<div class="row">
									<div class="col-md-4 ml-auto mr-auto">
										<h4>Randall Klein</h4>
										<img
											src="img/presenters25/Randall Klein.jpg"
											height="300px"
											width="250px"
										/>
									</div>
									<div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
										<h4>Tips & Tricks for Work with the Moveable Alphabet</h4>
										<p>
											Montessori early reading specialist Randall Klein will
											demonstrate intervention games and activities involving
											work with the Moveable Alphabet that give beginning and
											struggling readers a concrete understanding of the
											alphabetic principle and launch them into strong alphabet
											knowledge, confident decoding and proficient word
											identification.
										</p>
										<p>
											This session is geared toward instruction for beginning
											and struggling Pre-K & K-2 readers with the goal of
											preventing the risk of reading failure and of providing
											effective intervention activities. These games and
											activities for work with the Moveable Alphabet align with
											the principles of the Science of Reading.
										</p>
									</div>
								</div>
								<hr />
								<div class="row">
									<div class="col-md-4 ml-auto mr-auto">
										<h4>Kat Kirk</h4>
										<img
											src="img/presenters25/Kat Kirk.jpg"
											height="300px"
											width="200px"
										/>
									</div>
									<div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
										<h4>
											Community-Based Learning in Your School and Beyond
											(Collaborative Service Learning in Action)
										</h4>
										<p>
											In this workshop, we will explore various approaches to
											Service Learning in an experiential way. Discover new ways
											to implement Community-based Learning at your school, and
											in collaboration with other schools, that will foster
											valorization and self-efficacy through acts of generosity.
										</p>
									</div>
								</div>
								<hr />
								<div class="row">
									<div class="col-md-4 ml-auto mr-auto">
										<h4>Rachel Webb</h4>
										<img
											src="img/presenters25/Racheal Webb.jpg"
											height="300px"
											width="200px"
										/>
									</div>
									<div class="col-md-8 ml-auto mr-auto mt-auto mb-auto">
										<h4>Socratic Seminar in the Secondary Classroom</h4>
										<p>
											"It is more important to enable students to think for
											themselves than to fill their heads with 'right' answers."
											This workshop discusses the virtues of open ended
											conversation and practices skills needed to lead such
											discussions.
										</p>
									</div>
								</div>
							</collapse-item>
						</collapse>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {
	Button,
	InfoSection,
	FormGroupInput,
	Collapse,
	CollapseItem,
} from '@/components';

export default {
	name: 'umc-conference',
	bodyClass: 'umc-conference',
	components: {
		InfoSection,
		[Button.name]: Button,
		[FormGroupInput.name]: FormGroupInput,
		Collapse,
		CollapseItem,
	},

	data() {
		return {
			rows: [],
		};
	},
	metaInfo: {
		title: 'Utah Montessori - UMC Conference',
		meta: [{ name: 'description', content: 'Presenters Bios' }],
	},
};
</script>
<style scoped>
.center {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 75%;
	margin-top: 50px;
	margin-bottom: 20px;
}

img {
	border-radius: 20px !important;
}
</style>
